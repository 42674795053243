.table-container {
  margin: 20px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

input[type="text"], input[type="number"]{
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  background: none;
}

input[type="checkbox"] {
  transform: scale(1.5);
}

button {
  padding: 8px 16px;
  margin: 5px;
  cursor: pointer;
}

.fixed-width {
  width: 100px;
  text-align: center;
}

@media (max-width: 600px) {
  table {
    font-size: 14px;
  }
  input[type="text"], input[type="number"] {
    padding: 5px;
  }
  button {
    padding: 5px 10px;
  }
}